import React from "react";
import Container from "@material-ui/core/Container";

export default function UserAgreement(props) {
	return (
		<Container maxWidth="md">
			<div
				variant={'body2'}
				style={{
					lineHeight: 1.6,
					textAlign: 'justify',
					whiteSpace: 'pre-line',
					fontWeight: '500',
					fontFamily: 'Serif'
				}}>
				{'Youla平台（以下简称“本平台”或“我们”）由重庆月下童信息科技有限公司为您提供服务，在使用本平台前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受本协议，本协议内容如果以颜色、字体加粗等方式显著标识的，请着重阅读。\n' +
				'\n' +
				'除非您已阅读并接受本协议所有条款，否则您无权使用本协议所涉服务。您的登录、使用等行为将视为您已阅读并同意接受本协议各项条款的约束。\n' +
				'\n' +
				'本协议约定本平台与用户之间关于“Youla”（包括但不限于Youla产品、APP、网站等）产品服务（以下简称“本服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本平台有权依据国家政策、技术条件、产品功能等变化需要而进行修改本协议，本平台会将修改后的协议在App上予以公告，以便您及时了解本协议的最新版本。在本平台修改协议条款后，如果用户不接受修改后的条款，请立即停止使用本服务，用户继续使用本平台提供的本服务将被视为接受修改后的协议。\n' +
				'\n' +
				'一、注册账号\n' +
				'\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'1、对于未成年人，本平台不向其提供服务，任何18岁以下的用户不得使用本平台提供的服务。如果您未满18周岁，请立刻停止使用本服务。本平台将依赖用户提供的个人信息判断用户是否为未成年人。\n' +
					'\n' +
					'2、本平台有权对您的提供的账号信息进行审查。您应当对您提供的账号资料的真实性、合法性、准确性和有效性独立承担责任。如果因此给本平台或第三方造成损害的，您应当依法予以赔偿。\n' +
					'\n' +
					'3，您必须是具有中国国籍的单身人士才能注册成为本平台用户。为免歧义，此处“单身人士”系指尚未与他人缔结婚姻关系的任何具有中国国籍的公民，包括未婚、离异或是丧偶。在本平台提供服务过程中，如果您的状态发生变更，请及时修改，否则由此产生的所有责任均由您自行承担。\n' +
					'\n' +
					'4、您必须是账号的实际拥有人，仅代表您自己接受本平台的各项服务。账号禁止赠与、借用、租用、共享、转让或售卖，否则由此给他人或本平台造成损失的，由您承担所有责任。如果本平台发现使用者并非账号初始注册人，有权在未经通知的情况下采取封禁账号、回收账号等处理措施而无需向该账号使用人、初始注册人承担法律责任，由此带来的包括并不限于用户通讯中断、用户资料和道具、会员权益等清空等损失由用户自行承担。\n' +
					'\n' +
					'5、您必须保证以恋爱或婚姻为目的使用本平台服务，向本平台提供真实、准确、最新及完整的个人资料。\n' +
					'\n' +
					'6、鉴于“Youla”账号的绑定注册方式，您同意本平台在注册时将使用您提供的手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码用于注册。\n' +
					'\n' +
					'7、当您通过APP登录本平台账号并使用Youla身份认证服务时，我们将通过通过第三方leancloud数据存储存储你的身份资料，而后通过Youla后台管理系统人工对您的身份信息进行核实验查， 因此在为您提供该认证服务时，默认您同意我们通过此方式来获得您的合法意愿授权。\n' +
					'\n' +
					'您理解并同意，您应当根据我们认证流程要求向我们提交个人认证必须提供的最新、真实、准确及完整的信息及资料（包括身份证），同意我们可将您的个人身份信息传输至第三方leancloud服务平台以核验您的身份。您应当对我方执行此认证产生的结果自行承担责任，对于因您账号、密码、身份信息的泄露或产品/设备遗失或因您未安全退出账号导致的损失，由您自行承担。\n' +
					'\n' +
					'8、“Youla”部分功能如小事发布、推荐用户所属区域会基于地理位置进行发布，将依赖用户提供的所在城市信息采集用户的地理位置信息，故用户完成注册即表明用户同意并授权本平台提取、公开及使用用户的地理位置信息。如用户需要终止向其他用户公开其地理位置及其他个人信息，可自行在手机设置页面关闭“获取用户位置信息”。如用户需要冻结、注销账号，可联系平台内客服进行处理或在“设置”页面自行处理。\n' +
					'\n' +
					'9、您在使用本服务前需要通过手机号验证注册一个“Youla”账号。您登录本平台以后，可以自行设置账号信息，包括您的头像、照片、昵称、性别、所在城市、出生日期、星座、身高、毕业院校、学历、所属行业/职业、收入、个人说明、兴趣爱好、感情观、心仪的TA。您可自行提供姓名、身份证号、身份证、毕业院校、学历、学历证书进行身份和学历认证，本平台未要求的可无需提供。\n' +
					'\n' +
					'10、在用户注册及使用本服务时，本平台需要搜集能识别用户身份的个人信息以便本平台可以在必要时联系用户，或为用户提供更好的使用体验。本平台搜集的信息包括用户的头像、照片、昵称、性别、所在城市、出生日期、星座、身高、毕业院校、学历、所属行业/职业、收入、个人说明、兴趣爱好、感情观、心仪的TA；本平台同意对这些信息的使用将受限于Youla隐私政策的约束。\n' +
					'\n' +
					'11、依据《互联网用户账号信息管理规定》第八条的规定，用户注册、使用账号信息，不得有下列情形：\n' +
					'\n' +
					'（一）违反《网络信息内容生态治理规定》第六条、第七条规定；\n' +
					'\n' +
					'（二）假冒、仿冒、捏造政党、党政军机关、企事业单位、人民团体和社会组织的名称、标识等；\n' +
					'\n' +
					'（三）假冒、仿冒、捏造国家（地区）、国际组织的名称、标识等；\n' +
					'\n' +
					'（四）假冒、仿冒、捏造新闻网站、报刊社、广播电视机构、通讯社等新闻媒体的名称、标识等，或者擅自使用“新闻”、“报道”等具有新闻属性的名称、标识等；\n' +
					'\n' +
					'（五）假冒、仿冒、恶意关联国家行政区域、机构所在地、标志性建筑物等重要空间的地理名称、标识等；\n' +
					'\n' +
					'（六）以损害公共利益或者谋取不正当利益等为目的，故意夹带二维码、网址、邮箱、联系方式等，或者使用同音、谐音、相近的文字、数字、符号和字母等；\n' +
					'\n' +
					'（七）含有名不副实、夸大其词等可能使公众受骗或者产生误解的内容；\n' +
					'\n' +
					'（八）含有法律、行政法规和国家有关规定禁止的其他内容。\n' +
					'\n' +
					'12、您理解并同意：在您进行账号注册或使用本服务时，如发现您账号可能存在涉诈异常情形或风险的，本平台有权根据相关法律法规规定重新核验您的账号，并可根据风险情况，采取限期改正、限制功能、暂停使用、关闭账号、禁止重新注册以及本协议规定的其他处置措施。\n' +
					'\n' +
					'二、免责声明\n' +
					'\n' +
					'1、本平台不保证其提供的服务一定能满足用户及会员的要求和期望，也不保证服务不会中断，对服务的及时性、安全性、准确性都不作保证。\n' +
					'\n' +
					'2、对于用户通过本平台提供的服务传送的内容，本平台会尽合理努力按照国家有关规定严格审查，但无法完全控制经由本平台服务传送的内容，不保证内容的正确性、完整性或品质。因此用户在使用本平台服务时，可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，本平台均不为用户经由本平台服务以自我描述展示、聊天或其它方式传送的任何内容负责。但本平台有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本平台服务的全部或部分，保存有关记录，并根据国家法律法规、相关政策在必要时向有关机关报告并配合有关机关的行动。  \n' +
					'\n' +
					'3、对于用户上传的照片、资料（包括身高、收入状况、婚姻状况、脱单目标、感情状况、所在地、家乡、行业、自我介绍等)、证件，本平台已采用相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的用户负责。\n' +
					'\n' +
					'4、用户以自己的独立判断从事与交友相关的行为，并独立承担可能产生的不利后果和责任。如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，以及用户或家长发现未成年人使用本平台服务，请立即在本平台内举报或投诉该用户或联系客服处理，举报或投诉电话为13922120454，投诉邮箱：moonxt@yeah.net，本平台将依本协议约定进行处理。\n' +
					'\n' +
					'5、是否使用本平台服务下载、浏览或取得任何资料应由用户自行考虑并自负风险，因任何资料的下载、浏览而导致的用户软件系统的任何损坏或数据丢失等后果，本平台不承担任何责任。  \n' +
					'\n' +
					'6、本平台对所有用户自发组织的活动、自发成立的组织不负责任。  \n' +
					'\n' +
					'7、对于本平台策划、发起、组织或是承办的任何用户活动（包括但不限于收取费用以及完全公益的活动），本平台会按照国家有关规定进行运营，但本平台不对上述活动的效果向用户作出任何保证或承诺，也不担保活动期间用户自身行为的合法性、合理性。\n' +
					'\n' +
					'8、对于用户的投诉，本平台将尽合理努力认真核实，但不保证最终公之于众的投诉的真实性、合法性，对于投诉内容侵犯用户隐私权、名誉权等合法权利的，所有法律责任由投诉者承担，与本平台无关。  \n' +
					'\n' +
					'9、本平台未授权任何第三方销售任何Youla的线上服务产品，包括Youla会员。任何第三方出售本平台上述线上服务产品的，均可能属于非法的销售行为，本平台均不保证提供相应的服务。 \n' +
					'\n' +
					'10、用户理解并同意，因业务发展需要，本平台保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。\n' +
					'\n'}
				</div>
				{
					'三、服务事项\n' +
					'\n' +
					'1、本服务的具体内容由本平台根据实际情况提供，包括授权用户通过其账号进行寻觅嘉宾、获取推荐、聊天、查看我收藏的人、喜欢我的人、发布我的小事、编辑资料、身份认证等。本平台可以对其提供的服务予以变更，且本平台提供的服务内容可能随时变更，用户继续使用更新后服务时默认接受服务的更新。\n' +
					'\n'
				}
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'2、付费功能使用\n' +
					'\n' +
					'Youla部分增值服务需要付费使用，主要包括Youla会员，具体以购买页面展示信息为准。用户如需购买付费增值服务，可在相应服务的购买页面选购并支付。您理解并同意，Youla提供的增值服务为数字化商品，用户购买时应根据实际需求确认购买服务的种类、数量、时长、自动续费期限（如可选）等，一经付款即为确认，无法律或本协议规定的正当理由，用户不得再要求修改或退款，若您确有退款或修改的正当理由请联系客服处理。对于本平台依照法律法规的要求对违规用户进行封禁等处理的，用户不得再要求修改或退款。\n' +
					'\n' +
					'用户购买Youla付费功能，要求开具发票的，用户可联系本平台客服开具增值税发票，如产生运输费、快递费等由用户承担。\n' +
					'\n' +
					'您申请开通付费会员服务时，须根据页面提示支付相应的付费会员服务费用后方可完成开通。基于权益调整、市场与业务发展、本平台可能会调整付费会员服务开通所需会员服务费用。付费会员服务费用调整自公布之日起生效，您于生效前已开通的付费会员服务不受影响，但该付费会员服务到期后若需续费开通，则需按调整后已生效的费用标准支付。此外，在本平台降低收费服务的收费标准或者将收费服务改为免费服务提供时，本平台保留不对原付费用户提供退费或者费用调整之权利。\n' +
					'\n' +
					'您通过本平台指定方式开通付费会员服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及其他第三方原因导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，且不会因此向本平台提出任何主张或追究任何责任。\n' +
					'\n' +
					'您理解并同意：为改善用户体验、完善服务内容，本平台会对付费会员及/或其相关服务、权益、功能、界面等进行更新、修改，包括开发新功能/权益、删除旧功能/权益等。前述的更新、修改，本平台会在相应的服务页面进行展示或通知。\n' +
					'\n'}
				</div>
				{
				'3、非商业用途声明\n' +
				'\n' +
				'对于本平台所提供的服务，您不得用于任何商业用途，但经本平台明确认可或批准的商业使用除外。禁止非法和/或未经授权使用Youla服务，我们可以在不经任何通知的情况下删除用户档案文件中的商业广告、关联链接和其他形式的推销内容，并且可以因此而终止您的用户资格。对于任何非法或未经授权使用本服务的行为，我们将会采取适当的法律行动。\n' +
				'\n' +
				'四、保护用户个人隐私\n' +
				'\n' +
				'1、用户在注册账号或使用本服务的过程中，可能需要填写或提交一些必要的个人信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。\n' +
				'\n' +
				'2、用户个人信息包括：1）用户自行提供的用户个人信息（学历认证(如平台可选)中填写的姓名、身份证号、学历，使用服务时提供的共享信息）；2）其他方分享的用户个人信息；3）为提供服务而合法收集的用户必要个人信息（使用服务时系统自动采集的设备或产品信息，浏览历史信息，通讯时间信息，用户开启定位功能并使用服务时的地理位置信息）。\n' +
				'\n' +
				'其中个人隐私信息是指涉及用户个人身份或个人隐私的信息，包括用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在本平台服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。本平台保证在取得用户书面同意的情况下收集、使用或公开用户的个人隐私信息，用户同意本平台无需获得用户的另行确认与授权即可收集、使用或公开用户的非个人隐私信息。\n' +
				'\n' +
				'3、尊重用户个人信息的私有性是本平台的一贯制度，本平台将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者本平台发现存在发生前述情形的可能时，本平台将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与本平台联系。\n' +
				'\n' +
				'4、本平台未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：\n' +
				'\n' +
				'(1) 根据法律法规规定或有权机关的指示提供用户的个人隐私信息；\n' +
				'\n' +
				'(2) 由于用户将其用户密码告知他人或与他人共享注册账户与密码，由此导致的任何个人信息的泄漏，或其他非因本平台原因导致的个人隐私信息的泄露；\n' +
				'\n' +
				'(3) 用户自行向第三方公开其个人隐私信息；\n' +
				'\n' +
				'(4) 用户与本平台及合作单位之间就用户个人隐私信息的使用公开达成约定，本平台因此向合作单位公开用户个人隐私信息；\n' +
				'\n' +
				'(5) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；\n' +
				'\n' +
				'(6) 用户个人信息已经经过处理无法识别特定个人且不能复原。\n' +
				'\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'5、用户同意本平台可在以下事项中使用用户的个人信息：\n' +
					'\n' +
					'(1) 经用户明确同意让第三方共享资料；\n' +
					'\n' +
					'(2) 只有透露用户的个人信息，才能向用户提供用户所要求的产品及服务；\n' +
					'\n' +
					'(3) 为保护本平台、用户及社会公众的财产权利或人身权利；\n' +
					'\n' +
					'(4) 在提供本服务过程中将用户个人信息用于平台内嘉宾推荐的展示，任何登录/注册本平台的用户均可查看到推荐嘉宾的信息以及来自他人转发、分享的嘉宾信息；\n' +
					'\n' +
					'(5) 向用户及时发送重要通知，如产品更新、升级、产品验证本协议条款的变更；\n' +
					'\n' +
					'(6) 内部进行审计、数据分析和研究等，以改进产品、服务和与用户之间的沟通；\n' +
					'\n' +
					'(7) 依本协议约定，管理、审查用户信息及进行处理措施；\n' +
					'\n' +
					'(8) 受到黑客攻击、电脑病毒入侵导致用户个人信息泄漏的；\n' +
					'\n' +
					'(9) “Youla隐私政策”列出的各类情况；\n' +
					'\n' +
					'(10) 网络同步服务；\n' +
					'\n' +
					'(11) 提高会员的使用安全性并提供客户支持；\n' +
					'\n' +
					'(12) 被搜索引擎在搜索结果中显示；\n' +
					'\n' +
					'(13) 因用户使用本平台特定功能或因用户有潜在婚恋服务意愿，向代表我们提供产品或服务的合作单位或授权单位提供您的个人信息；\n' +
					'\n' +
					'(14) 向用户发送平台内短信提醒。\n' +
					'\n' +
					'(15) 适用法律法规规定的其他事项。\n'+
					'\n' +
						'在上述事项下，本平台将用户的个人信息提供给第三方不需要获得用户事先同意。如因此给用户造成任何损害，用户同意免除本平台的相应法律责任。除上述事项外，如未取得用户事先同意，本平台不会将用户个人隐私信息使用于任何其他用途。\n' +
						'\n' +
						'6、为了改善本平台的技术和服务，向用户提供更好的服务体验，本平台或可会自行收集使用或向第三方提供用户的非个人隐私信息。\n' +
						'\n' +
						'7、本平台保证在合法、正当与必要的原则下收集、使用或者公开用户个人信息且不会收集与提供的服务无关的用户个人信息。\n' +
						'\n' +
						'五、内容规范\n' +
						'\n' +
						'1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、个人信息、自我描述等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。\n' +
						'\n' +
						'2、用户不得利用“Youla”账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的违法内容：\n' +
						'\n' +
						'(1) 反对宪法所确定的基本原则的；\n' +
						'\n' +
						'(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；\n' +
						'\n' +
						'(3) 损害国家荣誉和利益的；\n' +
						'\n' +
						'(4) 煽动民族仇恨、民族歧视，破坏民族团结的；\n' +
						'\n' +
						'(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；\n' +
						'\n' +
						'(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；\n' +
						'\n' +
						'(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；\n' +
						'\n' +
						'(8) 侮辱或者诽谤他人，侵害他人合法权益的；\n' +
						'\n' +
						'(9) 不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；\n' +
						'\n' +
						'(10) 含有法律、行政法规禁止的其他内容的信息。\n' +
						'\n' +
						'3、用户不得利用“Youla”账号或本服务制作、上载、复制、发布、传播如下干扰“Youla”正常运营，以及侵犯其他用户或第三方合法权益的内容：\n' +
						'\n' +
						'(1) 含有任何性或性暗示的；\n' +
						'\n' +
						'(2) 含有辱骂、恐吓、威胁内容的；\n' +
						'\n' +
						'(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；\n' +
						'\n' +
						'(4) 涉及他人隐私、个人信息或资料的；\n' +
						'\n' +
						'(5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；\n' +
						'\n' +
						'(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。\n' +
						'\n' +
						'4、用户应坚持社会主义核心价值观，坚持正确积极导向，遵守平台内容规范，对自己的行为负责。\n' +
						'\n' +
						'六、使用规则\n' +
						'\n' +
						'1、用户不得利用“Youla”账号或本服务进行如下行为：\n' +
						'\n' +
						'(1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；\n' +
						'\n' +
						'(2) 强制、诱导其他用户关注、点击链接页面或分享信息的；\n' +
						'\n' +
						'(3) 虚构事实、隐瞒真相以误导、欺骗他人的；\n' +
						'\n' +
						'(4) 利用技术手段批量建立虚假账号的；\n' +
						'\n' +
						'(5) 利用“Youla”账号或本服务从事任何违法犯罪活动的；\n' +
						'\n' +
						'(6) 利用“Youla”账号出售、泄露嘉宾信息；\n' +
						'\n' +
						'(7) 利用“Youla”账号向他人推销“可代其向指定客户联系”的服务，包括但不限于猎头、广告营销等行为；\n' +
						'\n' +
						'(8) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；\n' +
						'\n' +
						'(9) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“Youla”正常运营或本平台未明示授权的行为。\n' +
						'\n' +
						'若用户有上述行为，本平台有权删除不良信息和违法信息、封禁、注销用户账号并追究用户相应的法律责任；若用户行为侵害他人任何权利而导致任何第三人对本平台提出任何索赔或请求，用户应当赔偿本平台或其他合作伙伴的损失，包括但不限于律师费、交通费、公证费和合理的调查费用等。\n' +
						'\n' +
						'2、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表本平台的观点、立场或政策，本平台对此不承担任何责任。\n' +
						'\n' +
						'3、本平台提供的服务中可能包括广告，您同意在使用的过程中显示本平台和第三方供应商、合作伙伴提供的广告，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您通过该等链接的网站或广告所购买的商品或服务，其交易行为仅存在于您与该商品或服务的提供者之间，与本平台无关，本平台不对用户与该商品或服务的提供者之间所产生的交易行为承担任何法律责任，您应予以特别留意。\n' +
						'\n' +
						'4、用户须对利用“Youla”账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与本平台无关。如因此给本平台或第三方造成损害的，用户应当依法予以赔偿。\n'}
				</div>
				{
				'\n' +
				'5、用户在投诉其他用户有违法行为或违反本协议情形时，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给本平台造成损失的，投诉人应对本平台履行相应的赔偿责任。 \n' +
				'\n' +
				'6、除非本平台书面许可，用户不得从事下列任一行为：\n' +
				'\n' +
				'(1) 对本平台进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本平台的源代码；\n' +
				'\n' +
				'(2) 对本平台或者本平台运行过程中释放到任何终端内存中的数据、运行过程中客户端与服务器端的交互数据，以及运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经本平台授权的第三方工具/服务接入本平台和相关系统；\n' +
				'\n' +
				'(3) 对本平台拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；\n' +
				'\n' +
				'(4) 删除本平台及其副本上关于著作权的信息；\n' +
				'\n' +
				'(5) 通过修改或伪造本平台运行中的指令、数据，增加、删减、变动本平台的功能或运行效果，或者将用于上述用途的产品、方法进行运营或向公众传播，无论这些行为是否为商业目的；\n' +
				'\n' +
				'(6) 对本平台提供之任何服务的任何部分进行“帧联（Framing）”或“镜像(Mirror)”。或使用超文本标记语言元标记（Meta Tags）或代码或其他提及本平台提供之任何服务的其他设备的信息，因任何目的，将任何人引导至任何其他平台；\n' +
				'\n' +
				'(7) 通过非本平台开发、授权的第三方产品、插件、外挂、系统，登录或使用本平台产品及服务，或制作、发布、传播非本平台开发、授权的第三方产品、插件、外挂、系统。\n' +
				'\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'7、您理解并同意：本平台有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告和配合调查等，用户应独自承担由此而产生的一切法律责任。在向您提供本服务过程中，如发现涉诈违法犯罪线索、风险信息的，本平台有权依照国家有关规定，根据涉诈风险类型、程度情况移送公安、金融、电信、网信等有权部门。\n'}
				</div>
				{
				'\n' +
				'8、用户在使用本平台服务时，遵守《中华人民共和国民法典》、《中华人民共和国著作权法》、《中华人民共和国保守国家秘密法》、《中华人民共和国反电信网络诈骗法》《全国人民代表大会常务委员会关于维护互联网安全的决定》、《中华人民共和国电信条例》、《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法、《计算机信息系统国际联网保密管理规定》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》等相关中国法律法规的规定。在任何情况下，如果本平台有理由认为用户或会员使用本平台服务过程中的任何行为，包括但不限于用户或会员的任何言论和其它行为违反或可能违反上述法律和法规的任何规定，本平台可在任何时候不经任何事先通知终止向该用户或会员提供服务。 \n' +
				'\n' +
				'七、管理账户\n' +
				'\n' +
				'1、用户有责任妥善保管注册账号信息及账号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或密码。在用户怀疑他人使用其账号或密码时，用户同意立即通知本平台。\n' +
				'\n' +
				'2、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，本平台在通知用户后有权依据协议中断或终止对违约用户“Youla”账号提供服务。同时，本平台保留在任何时候收回“Youla”账号、用户名的权利。\n' +
				'\n' +
				'3、“Youla”账号的所有权归本平台所有，用户完成申请注册手续后，获得“Youla”账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。本平台因经营需要，有权回收用户的“Youla”账号。\n' +
				'\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'4、用户可以自行填写、查询、浏览、更改“Youla”账号上的个人资料、注册信息及传送内容。\n' +
					'\n' +
					'5、您可以在【我】-【设置】-【注销账号】提交注销申请。在您申请账号注销时，我们可能会要求您进行身份验证，以保障账号的安全。我们将在收到您的账号注销申请后7天内完成注销处理，如果您在7天内继续使用Youla账号并撤销申请，我们可恢复您的账号信息。否则，您的账号将被正式注销，即对您的个人信息进行删除或匿名化处理，使其保持不可被检索、访问的状态。\n' +
					'\n' +
					'6、用户注册“Youla”软件账号激活该网络软件服务后，连续180天没有使用网络服务，则自第180天当天的第24小时起，本平台有权回收账号，以免造成资源浪费，由此带来的包括并不限于用户通信中断、个人资料、邮件丢失等损失由用户自行承担。\n'}
				</div>
				{
				'\n' +
				'八、数据储存\n' +
				'\n' +
				'1、非因平台原因导致用户在本服务中相关数据（包括个人信息数据、账号中虚拟财产、道具）的删除或储存失败的，平台不承担任何责任。\n' +
				'\n' +
				'2、本平台可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。\n' +
				'\n' +
				'3、如用户停止使用本服务或本服务终止，本平台可以从服务器上永久地删除用户的数据。本服务停止、终止后，本平台没有义务向用户返还任何数据。\n' +
				'\n' +
				'4、对于用户数据储存服务，本平台采用第三方leancloud服务，用户需理解并同意平台与第三方正常合作关系。\n' +
				'\n' +
				'九、知识产权的声明\n' +
				'\n' +
				'1、除本服务中涉及广告的知识产权由相应广告商享有外，本平台在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归本平台所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。\n' +
				'\n' +
				'2、除另有特别声明外，本平台提供本服务时所依托产品的著作权、专利权及其他知识产权均归本平台所有。\n' +
				'\n' +
				'3、本平台在本服务中所涉及的“Youla”图形、文字或其组成，以及其他本平台标志及产品、服务名称（以下统称“本平台标识”），其著作权或商标权归本平台所有。未经本平台事先书面同意，用户不得将本平台标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理本平台标识的行为。\n' +
				'\n' +
				'4、上述及其他任何本平台或相关广告商依法拥有的知识产权均受到法律保护，未经本平台或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。\n' +
				'\n' +
				'5、任何单位或个人通过本平台提供服务的内容可能涉嫌侵犯权利人的知识产权或信息网络传播权及其他合法权益的，权利人应该及时向本平台提出书面权利通知投诉，并提供身份证明、权属证明及详细侵权情况证明。本平台在收到上述法律文件后，将会依法尽快断开相关链接内容。本平台提供投诉通道：moonxt@yeah.net。如投诉中未向本平台提供合法有效的证明材料，本平台有权不采取任何措施。\n' +
				'\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'十、法律责任\n' +
					'\n' +
					'1、用户理解并同意，本平台有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。\n' +
					'\n' +
					'2、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿本平台与合作公司、关联公司，并使之免受损害。\n' +
					'\n' +
					'3、如果本平台发现或收到他人举报或投诉用户违反本协议约定的，本平台有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁 、设备封禁 、功能封禁、账号注销的处罚，且通知用户处理结果。\n' +
					'\n' +
					'十一、免责事由声明\n' +
					'\n' +
					'1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、传染病、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，本平台将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，本平台及合作单位在法律允许的范围内免责。\n' +
					'\n' +
					'2、我们会依据本协议为您提供服务，但您已理解并同意我们不保证所提供的服务将最终帮助您找到您的伴侣。如您在使用筛选服务时，未达到您满意的结果，我们建议您可以相应调整筛选条件。您承认对于您利用本平台提供的服务或信息而从事的各类行为，包括在本平台发布各类信息，利用本平台提供之信息联系其他用户等，均为您个人行为且您个人对该类行为承担完全责任。\n' +
					'\n' +
					'3、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它产品或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。\n' +
					'\n' +
					'4、本平台对不可抗力导致的损失不承担责任。本协议所称不可抗力包括：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机、移动设备或互联网相关技术缺陷、互联网覆盖范围限制、计算机、移动设备病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。\n' +
					'\n' +
					'5、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，本平台会尽合理努力按照国家有关规定严格管控。\n' +
					'\n' +
					'6、用户理解并确认，本平台需要定期或不定期地对“Youla”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，本平台无需为此承担任何责任。\n' +
					'\n' +
					'7、本平台依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成本平台的义务或承诺，本平台不能保证及时发现违法违规或违约行为或进行相应处理。\n' +
					'\n' +
					'8、在任何情况下，本平台均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用本平台服务而遭受的利润损失，承担责任（即使本平台已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，本平台对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用本平台提供的服务而支付给本平台的费用(如有)。\n'}
				</div>
				{
				'\n' +
				'十二、服务的变更、中断、终止\n' +
				'\n' +
				'1、本平台自行决定终止运营时或本平台因其他任何原因终止运营时，本平台会按照国家有关终止运营的相关法律法规规定处理终止运营相关事宜，以保障用户合法权益。\n' +
				'\n' +
				'2、如发生下列任何一种情形，本平台有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：\n' +
				'\n' +
				'(1) 按照法律规定或有权机关的要求；\n' +
				'\n' +
				'(2) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；\n' +
				'\n' +
				'(3) 出于安全的原因或其他必要的情形。\n' +
				'\n' +
				'(4) 用户违反相关法律法规或本协议的约定；\n' +
				'\n' +
				'十三、其他说明\n' +
				'\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'1、本协议的任何条款无论因何种原因无效或不具可执行性的，不影响其他条款的效力。\n' +
					'\n' +
					'2、本平台郑重提醒用户注意本协议中免除本平台责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。\n' +
					'\n' +
					'3、本协议的效力、解释及纠纷的解决，适用于中华人民共和国的现行法律。本协议签订地为重庆市北碚区。若您和本平台之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。本协议条款因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。 \n' +
					'\n' +
					'4、由于互联网高速发展，您与本平台签署的本协议列明的条款可能并不能完整罗列并覆盖您与Youla所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，Youla隐私政策、Youla平台信息内容管理规范、Youla会员协议、注销须知等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用Youla平台服务，视为您同意上述补充协议。\n'}
				</div>
				{
				'\n' +
				'5、请您在发现任何违反本服务协议以及其他任何单项服务的服务条款、Youla各类公告之情形时，通知Youla。您可以通过如下联络方式同Youla联系∶\n' +
				'联系邮箱：contact@moonxt.cn\n' +
				'联系电话：13922120454\n' +
				'\n' +
				'更新时间：2024年03月27日\n' +
				'生效时间：2023年11月20日'}
			</div>
		</Container>
	)
}
