import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RootRoute from './config/routes';
import {
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import reportWebVitals from './reportWebVitals';
const theme = createTheme({
  palette: {
    type: 'dark',
    background: {
      paper: '#2A2E39',
      default: '#17181B',
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RootRoute/>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
