import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import PrivacyPolicy from "./component/PrivacyPolicy";
import UserAgreement from "./component/UserAgreement";
import {vip_agreement} from "./assert/data/agreement/vip-agreement";
import {useParams} from "react-router-dom";

export default function AppAgreement(props) {
  const params = useParams();
  let title = null;
  let Component = null;
  if(params?.id === 'user-agreement'){
    title = 'Youla 用户协议';
    Component = <UserAgreement />;
  } else if(params?.id === 'privacy-policy'){
    title = 'Youla 隐私政策';
    Component = <PrivacyPolicy />;
  } else if(params?.id === 'vip-agreement'){
    title = 'Youla 会员协议';
    Component = <div
      variant={'body2'}
      style={{
        lineHeight: 1.6,
        textAlign: 'justify',
        whiteSpace: 'pre-line',
        fontWeight: '500',
        fontFamily: 'Serif'
      }}>{vip_agreement}</div>;
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md" component="main">
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center">
          <Typography
            style={{
              fontSize: 17,
              fontWeight: 'Regular',
              fontFamily: 'Serif',
              marginBottom: 22,
            }}
            variant={'h6'}
            color={'inherit'}>
            {title ?? ''}
          </Typography>
          {Component}
        </Grid>
      </Container>
    </React.Fragment>
  )
}
