import React from "react";
import {
	Route,
	Routes,
	HashRouter,
} from "react-router-dom";
import App from "../App";
import AppAgreement from "../AppAgreement";

//在这必须要引用，否则路由属性传递不到

function RootRoute(){
	return (
		<HashRouter>
			<Routes>
				<Route path="/" element={<App />} />
				<Route
					path="/:id"
					element={
						<AppAgreement
							title={'Youla 隐私政策'}
							agreementType={'privacy_policy'}
						/>
					}
				/>
			</Routes>
		</HashRouter>
	)
}

export default RootRoute;
