export const vip_agreement = `
重庆月下童信息科技有限公司（以下简称“本公司”）特别提醒您，您在注册成为Youla会员之前，请仔细阅读《Youla会员协议》（以下简称“本协议”），确保您已经充分理解各条款，包括免除本公司责任的条款及限制会员权利的条款。请您阅读后选择是否接收本协议。除非您接受本协议所有条款，否则您不可注册以及登录或使用本协议所涉及的服务。当您注册、登录、使用Youla app，将视为对本协议的接受，并同意接受各项条款的约束。

本协议是本公司与您之间关于“Youla会员”服务的各项事项约定。“会员”是指注册、登录、使用本协议项下本公司提供的各种服务的个人。Youla会员服务是有偿的增值服务，您必须完全同意本协议，并完成付费，才能享受相关服务。

本协议是基于“Youla”的《用户协议》而制定，是其不可分割的一部分。同时，本公司不时发布的关于会员服务的各种规范、规则等也是本协议的一部分。您选择接受本协议，开通会员服务成为会员用户，即表示您同意接受本协议、《用户协议》及各种规范、规则的各项约束，并将遵守。如果您不接受本协议，则不能享受Youla会员服务。


一、简则

1、Youla会员必须遵守Youla《用户协议》及本协议条款。

2、Youla用户是指完成全部注册流程，愿意接受Youla《用户协议》并在Youla相关使用规范的规定下使用Youla付费服务的注册用户。

3、Youla会员服务是向Youla用户提供的有偿增值服务，用户向Youla支付相关费用后，方可享受专门为会员提供的各项服务。

4、Youla是由重庆月下童信息科技有限公司研发并运营的移动社交产品。


二、会员费用

1、Youla会员增值服务有效期有不同选择，具体有效期和资费标准以会员中心页面公布的为准，用户可直接选择iOS内购或第三方支付方式进行购买。本公司将根据不同的会员类型、购买方式和数量，向用户提供不同幅度的优惠，具体优惠政策以会员中心相关页面的公告、说明或会员支付页面显示的内容为准。

2、您可以通过登录Youla付费会员中心查询您的账号信息详情，包括已开通的会员类型、服务内容、服务期限等。

3、本公司有权基于自身业务发展需要调整会员服务费用标准，调整后的费用标准将在会员中心支付页面显示，用户按调整后的服务费用标准支付后方可享有或延续会员。已按原会员服务费用标准购买会员服务的用户，其会员在已取得的会员期限内不受影响。


三、服务事项

1、本公司将为会员提供多种专属特权服务，具体内容以会员中心页面展示内容为准。

2、本公司有权基于自身业务发展需要调整会员服务内容。就前述调整，本公司将在相应页面进行通知或公告，您也可以通过本公司查询最新的会员服务内容。


四、会员的开通与终止

1、用户完成注册程序并通过Youla平台提供的付费途径完成会员费用的支付后，即取得相应Youla会员。

2、提交申请并开通会员时，您需确保提交的个人资料真实、准确、完整、合法有效。当个人资料发生变化时，应及时对注册资料予以修改、更新，否则由此造成的会员权利不能全面有效行使或其他任何后果、责任由您自行承担。

3、会员有效期自Youla会员服务开通之时起算，具体期限可以登录Youla“会员中心”页面查看。

4、会员有效期届满后，本公司将终止向您提供会员增值服务，您可以通过续交会员服务费用延续会员。

5、会员在会员有效期内续费，会员有效期将在原有效期基础上相应顺延。


五、会员的权利和义务

1、Youla会员平等享有会员的权利，同时应遵守本公司的各项规范、规则，包括但不限于本协议、《用户协议》、《隐私政策》等。

2、因违反本协议第五条第1款所述的相关协议或平台规则导致会员服务被暂停使用而导致的损失，由您自行承担。

3、会员有效期内，会员可以享受相应的付费增值服务。

4、在会员有效期内，您主动终止或取消会员的，本公司不负责退还与剩余会员有效期对应的服务费用。

5、会员自行承担在Youla中传送、发布信息及使用Youla免费服务或收费服务的法律责任，会员使用Youla服务应遵守各项法律法规、规章、规范性文件（以下简称“法律法规”）以及平台协议及规则。

6、会员开通后，您应谨慎合理的保存、使用账号，不得将账号借予他人使用。否则，您应承担由此产生的全部责任。同时，Youla在该种情况下有权作出独立判断，可采取暂停或关闭用户会员等措施。

7、会员服务仅限于申请账号个人使用，不得用于商业等用途；会员服务期内不能在Youla帐号之间转移，不得赠与、借用、转让或售卖。

8、会员不得以盗窃、利用系统漏洞等非法途径以及在未获Youla授权的非法销售Youla会员的网站上获取或购买会员服务，否则因此导致的损失由会员自行承担。


六、本公司的权利和义务

1、本公司应保证会员服务的正常使用，出现技术故时应尽快排除，但对于会员因此产生的任何损失，本公司不承担责任。

2、因不可抗力造成会员服务提供的中断或其它缺陷，本公司不承担任何责任，但将尽力减少因此给会员造成的损失和影响。

3、会员应妥善保管Youla账号密码，因会员自身原因导致账号密码泄露而造成的损失，由会员自行承担。但为避免账号被盗用等情形给会员造成损失，Youla会在您举报后采取合理措施避免第三方对会员账号实施操作行为，而不论该第三方基于何种目的、获取方式是否善意。但Youla采取措施的前提需会员提供相应的材料以证明其为账号的合法使用者，您应予以配合。

4、会员在使用Youla服务时，故意作出有损本公司、其他Youla用户合法权益的行为或违反《用户协议》的相关约定，本公司有权取消会员或中断一项或多项会员服务而无须给予任何补偿，与此同时，本公司保留追究会员法律责任的权利。

5、会员在使用Youla服务时，存在违反法律法规的行为，本公司有权取消会员而无须给予任何补偿，且会员须自行承担全部责任。


七、其他事项

1、本公司不时发布的关于会员服务的规范、规则等，是本协议不可分割的一部分。

2、本协议未约定事项，参照《用户协议》执行；本协议约定与《用户协议》不一致的，以本协议的约定为准。

3、本协议的解释，效力及纠纷的解决，适用中华人民共和国法律。本协议签订地为重庆市北碚区。若会员和本公司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，会员同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。本协议条款因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。

4、本协议可由本公司随时更新，更新后的协议一旦公布即代替原来的协议内容，您可在本公司内查阅最新版本。

更新时间：2023年09月30日
生效时间：2023年09月30日`
